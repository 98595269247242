import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Question extends Component {
  static propTypes = {
    question: PropTypes.string,
    answer: PropTypes.string,
    isOpen: PropTypes.bool,
    handleOpen: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.answerRef = React.createRef()

    this.state = {
      scrollHeight: 0
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props

    if (nextProps.isOpen === true && isOpen === false) {

      const { scrollHeight } = this.answerRef.current

      this.setState({ scrollHeight })
    }

    if (nextProps.isOpen === false && isOpen === true) {
      this.setState({ scrollHeight: 0 })
    }
  }


  render() {
    const { scrollHeight } = this.state
    const { answer, handleOpen, isOpen, question } = this.props

    return (
      <div className="FAQQuestion">
        <hr />
        <div className="FAQQuestionHeader" onClick={handleOpen}>
          <h3>{question}</h3>
          <p data-expanded={isOpen}></p>
        </div>

        <div className="FAQAnswer" data-visible={isOpen} ref={this.answerRef} style={{ height: `${scrollHeight}px`}}>
          <p>
            {answer}
          </p>
        </div>
      </div>
    )
  }
}

export default Question
