import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Heroes.scss'

class Heroes extends Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    heroes: PropTypes.arrayOf(
      PropTypes.shape({
        thumbnail: PropTypes.shape({
          url: PropTypes.string
        }),
        link: PropTypes.shape({
          url: PropTypes.string
        }),
        hero_name: PropTypes.string,
        company_name: PropTypes.string
      })
    )
  }

  render() {
    const {
      title,
      subtitle,
      heroes
    } = this.props

    const heroesListItems = heroes.map((hero, index) => (
      <div className="HeroesHero" key={index}>
        <a href={hero.link.url}>
          <div className="HeroesHeroImage" style={{ backgroundImage: 'url(' + hero.thumbnail.url + ')'}}>
          </div>
          <div className="HeroesHeroName">
            {hero.hero_name}
          </div>
          <div className="HeroesHeroCompany">
            {hero.company_name}
          </div>
        </a>
      </div>
    ))

    return (
      <div className="Heroes">
        <div className="HeroesContent">
          <h2>{title}</h2>
          <h5>{subtitle}</h5>
          <div className="HeroesHeroes">
            {heroesListItems}
          </div>
        </div>
      </div>
    )
  }
}

export default Heroes
