import React, { Component } from 'react'
import InputSlider from 'src/components/InputSlider'
import QredLoanCalculator from 'qred-loan-calculator'
import PaymentPlan from './PaymentPlan'
import './LoanCalculator.scss'

class LoanCalculator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentAmount: 50000,
      currentTermInMonths: 6,
      currentSortTerm: 6,
      loanCalculator: null,
      showPaymentPlan: false,
      firstMonthFree: false
    }

    this.changeCurrentSortTerm = this.changeCurrentSortTerm.bind(this)
    this.handleClickLoanTimeButton = this.handleClickLoanTimeButton.bind(this)
    this.handleChangeLoanAmount = this.handleChangeLoanAmount.bind(this)
    this.getFormattedAmount = this.getFormattedAmount.bind(this)
    this.handleTogglePaymentPlan = this.handleTogglePaymentPlan.bind(this)
  }

  componentDidMount() {
    const loanCalculator = new QredLoanCalculator({
      // market: this.$store.state.locale
      market: 'se'
    })

    this.setState({
      loanCalculator,
      currentAmount: loanCalculator.properties.amount,
      currentSortTerm: loanCalculator.properties.termInMonths,
      currentTermInMonths: loanCalculator.properties.termInMonths
    })
  }

  changeCurrentSortTerm(currentSortTerm) {
    this.setState({ currentSortTerm })
  }

  handleClickLoanTimeButton(value) {
    const { loanCalculator, currentTermInMonths } = this.state

    if (value !== currentTermInMonths) {
      this.setState({
        currentTermInMonths: value,
        currentSortTerm: value
      })

      loanCalculator.setTermInMonths(value)
    }
  }

  handleChangeLoanAmount(value) {
    const { loanCalculator, currentAmount } = this.state

    if (value !== currentAmount) {
      this.setState({ currentAmount: value })
      loanCalculator.setAmount(value)
    }
  }

  getFormattedAmount(amount) {
    // let currencyFormatter = this.$t('homepage.loanCalculator.currencyFormatter')
    // let currencyCode = (this.loanCalculator) ? this.loanCalculator.currency : this.$t('homepage.loanCalculator.currencyCode')
    let currencyFormatter = 'sv-SE'
    let currencyCode = 'SEK'
    
    if (!currencyFormatter) {
      currencyFormatter = 'sv-SE'
    }
    if (!currencyCode) {
      currencyCode = 'SEK'
    }
    if (typeof amount !== "number") {
      return amount;
    }
    var formatter = new Intl.NumberFormat(currencyFormatter, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    return formatter.format(amount);
  }

  handleTogglePaymentPlan() {
    const { showPaymentPlan } = this.state

    this.setState({ showPaymentPlan: !showPaymentPlan })
  }

  render() {
    const { loanCalculator, showPaymentPlan, currentTermInMonths, currentAmount, currentSortTerm } = this.state

    if (!loanCalculator)
    return (
      <></>
    )

    const { firstMonthFree } = this.props

    const { loanRange, properties, monthlyFee, monthlyAmortisation, totalToPay } = loanCalculator

    return (
      <>
        <div className="LoanCalculator">
          <div className="LoanCalculatorContent">
            <h2>Mitt företagslån</h2>
            <div className="LoanCalculatorSections">
              <div className="LoanCalculatorSection">
                <h4>Önskat lånebelopp</h4>

                <InputSlider
                  formatter={ (amount) => this.getFormattedAmount(amount) }
                  handleChange={ (amount) => this.handleChangeLoanAmount(amount) }
                  initialValue={properties.amount}
                  inverse
                  label=""
                  max={loanRange.max}
                  min={loanRange.min}
                  step={10000}
                />
              </div>

              <div className="LoanCalculatorSection">
                <h4>Lånetid</h4>

                <div className="LoanTimeButtonGroup">
                  <button className="LoanTimeButton" value="6" onClick={() => this.handleClickLoanTimeButton(6)} data-selected={properties.termInMonths === 6}>
                    <div className="ButtonContent">
                      <span>6</span>
                      <span className="TimePeriod">Månader</span>
                    </div>
                  </button>

                  <button className="LoanTimeButton" value="9" onClick={() => this.handleClickLoanTimeButton(9)} data-selected={properties.termInMonths === 9}>
                    <div className="ButtonContent">
                      <span>9</span>
                      <span className="TimePeriod">Månader</span>
                    </div>
                  </button>

                  <button className="LoanTimeButton" value="12" onClick={() => this.handleClickLoanTimeButton(12)} data-selected={properties.termInMonths === 12}>
                    <div className="ButtonContent">
                      <span>12</span>
                      <span className="TimePeriod">Månader</span>
                    </div>
                  </button>
                </div>
              </div>

              <div className="LoanCalculatorSection">
                <h4>Månadsavgift</h4>

                <h2 className="LoanCalculatorSectionLargeAmount">{ this.getFormattedAmount(monthlyFee.value) }</h2>

                <p className="LoanCalculatorSectionOpenPlan" onClick={this.handleTogglePaymentPlan}>Se betalningsplan</p>

                <p>*Avgiften är ett exempel. Den faktiska månadsavgiften baseras på ditt företags Qred-score. Du betalar endast för de månader du använder lånet.</p>
              </div>

              <div className="LoanCalculatorSection">
                <h4>Amortering per månad</h4>
                <h4 className="LoanCalculatorSectionAmount">{ this.getFormattedAmount(monthlyAmortisation.value) }</h4>

                <h4>Total att betala</h4>
                <h4 className="LoanCalculatorSectionAmount">{ this.getFormattedAmount(totalToPay.value) }*</h4>
              </div>
            </div>
          </div>
        </div>
        <PaymentPlan
          showPaymentPlan={showPaymentPlan}
          handleTogglePaymentPlan={this.handleTogglePaymentPlan}
          loanCalculator={loanCalculator}
          currentAmount={currentAmount}
          currentSortTerm={currentSortTerm}
          currentTermInMonths={currentTermInMonths}
          firstMonthFree={firstMonthFree}
          getFormattedAmount={this.getFormattedAmount}
          handleChangeCurrentSortTerm={this.changeCurrentSortTerm}
        />
      </>
    )
  }
}

export default LoanCalculator