import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Partners.scss'

class Partners extends Component {
  static propTypes = {
    title: PropTypes.string,
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        partner_description: PropTypes.string,
        partner_link: PropTypes.object,
        partner_name: PropTypes.string,
        partner_image: PropTypes.shape({
          url: PropTypes.string
        })
      })
    )
  }

  static defaultProps = {
    partners: []
  }

  render() {
    const { partners, title } = this.props

    const partnersListItems = partners.map((partner, index) => (
      <div className="PartnersSectionPartner" key={index}>
        <a href={partner.partner_link.url}>
          <div className="PartnersSectionImage" style={{ backgroundImage: 'url(' + partner.partner_image.url + ')' }}>
          </div>
          <h4>{partner.partner_name}</h4>
          <p>{partner.partner_description}</p>
        </a>
      </div>
    ))

    return (
      <div className="PartnersSection">
        <div className="PartnersSectionContent">
          <h2>{title}</h2>
          <div className="PartnersSectionPartners">
            {partnersListItems}
          </div>
        </div>
      </div>
    )
  }
}

export default Partners
