import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler'
import './Select.scss'

class Select extends Component {
  static propTypes = {
    theme: PropTypes.string,
    handleChange: PropTypes.func,
    selected: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      })
    )
  }

  static defaultProps = {
    items: [],
    theme: 'default'
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false
    }

    this.buttonRef = React.createRef();
    this.listRef = React.createRef();

    this.toggleList = this.toggleList.bind(this)
    this.updatePosition = this.updatePosition.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.handleClickedListItem = this.handleClickedListItem.bind(this)
  }

  onBlur(e) {
    const { open } = this.state

    if (open) {
      this.setState({ open: false })
    }
  }

  toggleList() {
    const { open } = this.state

    this.setState({ open: !open })

    if (!open) {
      this.updatePosition()
    }
  }

  updatePosition() {
    const el = this.listRef.current

    el.style.bottom = null
    el.style.top = null

    const rect = el.getBoundingClientRect()
    const isVisible = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)

    if (isVisible) {
      el.style.top = '100%'
    } else {
      el.style.bottom = '100%'
    }
  }

  handleClickedListItem(item) {
    this.toggleList()
    this.props.handleChange(item)
  }

  render() {
    const { selected, items, theme } = this.props
    const { open } = this.state
    const openclassName = (open) ? 'SelectOpen' : ''
    let defaultLabel = ''

    const listItems = items.map((item, index) => {
      if (selected && (selected.value === item.value)) {
        return null
      }

      return (
        <div
          className="ListItem"
          key={index}
          data-value={item.value}
          onClick={() => this.handleClickedListItem(item)}
        >
          { item.label }
        </div>
      )
    })

    return (
      <div className={`Select ${openclassName}`} data-style={theme}>
        <OutsideClickHandler onOutsideClick={this.onBlur}>
          <div className="SelectButton" ref={this.buttonRef} onClick={this.toggleList}>{ (selected) ? selected.label : defaultLabel } <i className="icon icon-dropdown" /></div>
          <div className="SelectList" ref={this.listRef}>
            {listItems}
          </div>
        </OutsideClickHandler>
      </div>
    )
  }
}

export default Select
