import React, {Component} from "react"
import { graphql } from 'gatsby'
import parseBlocks from 'utils/parseBlocks'
import parseFields from 'utils/parseFields'
import Hero from 'src/components/Hero'
import ApplicationSteps from 'src/components/ApplicationSteps'
import Partners from 'src/components/Partners'
import Contact from 'src/components/Contact'
import Heroes from 'src/components/Heroes'
import LoanCalculator from 'src/components/LoanCalculator'
import FAQ from 'src/components/FAQ'

import 'src/styles/App.scss'

class PageTemplate extends Component {
  constructor(props) {
    super(props)

    this.prepareACFData = this.prepareACFData.bind(this)
  }

  prepareACFData(acf) {
    const acfData = {}

    if (acf) {
      // Setup Homepage Hero data
      if (acf.homepage_hero_title) {
        acfData.hero = {}

        acfData.hero.title = acf.homepage_hero_title

        if (acf.homepage_hero_dotpoints) {
          acfData.hero.dotpoints = acf.homepage_hero_dotpoints
        }
      }
    }

    return acfData
  }

  renderBlock(block) {
    const fields = parseFields(block.rows)

    switch (block.block) {
      case 'partner-integrations':
        return <Partners
          key={block.block}
          title={fields.homepage_partner_title}
          partners={fields.homepage_partners}
        />
      case 'contact':
        return <Contact
          key={block.block}
          title={fields.homepage_contact_title}
          text={fields.homepage_contact_text}
          email={fields.homepage_contact_email}
          openingHours={fields.homepage_contact_opening_hours}
          phoneNumber={fields.homepage_contact_phone_number}
        />
      case 'heroes':
        return <Heroes
          key={block.block}
          title={fields.title}
          subtitle={fields.subtitle}
          heroes={fields.heroes}
        />
      case 'application-steps':
        return <ApplicationSteps
          key={block.block}
          applicationSteps={fields.application_steps}
        />
      case 'loan-calculator':
        return <LoanCalculator
          key={block.block}
        />
      case 'faq':
        return <FAQ
          key={block.block}
          title={fields.title}
          seeAllLink={fields.see_all_link}
          seeAllTtext={fields.see_all_text}
          questions={fields.questions}
        />
      default:
        return null
    }
  }

  render() {
    const { currentPage } = this.props.data
    const blocks = parseBlocks(currentPage.blocks)
    let acfData = {}

    if (currentPage.acf) {
      acfData = this.prepareACFData(currentPage.acf)
    }

    return (
      <>
        <Hero componentData={acfData.hero || null} />
        {blocks.map(block => {
          return this.renderBlock(block)
        })}
      </>
    )
  }
}

export default PageTemplate

export const query = graphql`
  query currentPageQuery($id: String!, $language: String!) {
    ...Menus,
    currentPage: wordpressTranslationsPages(id: { eq: $id }, language: { eq: $language }) {
      title
      content
      slug
      id
      date(formatString: "MMMM DD, YYYY")
      blocks {
        block
        rows {
          key
          json
        }
      }
      acf {
        homepage_hero_title
        homepage_hero_dotpoints {
          text
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
