import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import PropTypes from 'prop-types'
import Select from 'src/components/Select'
import './PaymentPlan.scss'

class PaymentPlan extends Component {
  static propTypes = {
    showPaymentPlan: PropTypes.bool,
    handleTogglePaymentPlan: PropTypes.func,
    loanCalculator: PropTypes.object,
    currentAmount: PropTypes.number,
    currentSortTerm: PropTypes.number,
    currentTermInMonths: PropTypes.number,
    firstMonthFree: PropTypes.bool,
    getFormattedAmount: PropTypes.func,
    handleChangeCurrentSortTerm: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedSortType: null
    }

    this.getPaymentsTableData = this.getPaymentsTableData.bind(this)
    this.getTotalsData = this.getTotalsData.bind(this)
    this.sortedTotalToPay = this.sortedTotalToPay.bind(this)
    this.getSelectMonths = this.getSelectMonths.bind(this)
    this.changeSelectedSortType = this.changeSelectedSortType.bind(this)
  }

  changeSelectedSortType(selectedSortType) {
    const { handleChangeCurrentSortTerm } = this.props

    this.setState({
      selectedSortType
    })

    handleChangeCurrentSortTerm(selectedSortType.value)
  }

  getTotalsData() {
    const { loanCalculator, firstMonthFree, currentSortTerm } = this.props
    const monthlyFee = (loanCalculator) ? loanCalculator.monthlyFee.value : 0
    const totalToPay = (loanCalculator) ? loanCalculator.totalToPay.value : 0
    const totalOne = totalToPay
    let totalTwo = this.sortedTotalToPay()
    let totalFee = monthlyFee * currentSortTerm
    const saving = totalOne - totalTwo
    const totalAmortering = totalTwo - totalFee

    if (firstMonthFree) {
      totalTwo = totalTwo - monthlyFee
      totalFee = monthlyFee * (currentSortTerm - 1)
    }

    return {
      saving,
      totalTwo,
      totalOne,
      totalFee,
      totalAmortering
    }
  }

  sortedTotalToPay() {
    const { loanCalculator, currentAmount, currentSortTerm } = this.props
    const { interest, scalingRate } = (loanCalculator) ? loanCalculator.interest : { interest: 0, scalingRate: 0 }

    return currentAmount + Math.min(currentAmount, 100000) * (interest / 100) * currentSortTerm + Math.max((currentAmount - 100000), 0) * (scalingRate / 100) * currentSortTerm
  }

  getPaymentsTableData() {
    const { loanCalculator, currentAmount, currentTermInMonths, currentSortTerm, firstMonthFree } = this.props
    const monthlyFee = (loanCalculator) ? loanCalculator.monthlyFee.value : 0
    const monthlyAmortisation = (loanCalculator) ? loanCalculator.monthlyAmortisation.value : 0
    const zeroFields = []
    const monthlyPayments = []

    // calculate monthly payments
    const fee = monthlyFee
    let amortisation = monthlyAmortisation
    let lastAmort = currentAmount - currentSortTerm * amortisation + amortisation
    let lastMonthlyTotal = lastAmort + fee

    for (let i = 1; i <= currentSortTerm; i++) {
      let monthlyTotal = amortisation + fee

      if (firstMonthFree) {
        if (i === 1) {
          monthlyTotal = 0
          if (currentSortTerm === i) {
            lastMonthlyTotal = lastAmort
          }
        } else {
          amortisation = currentAmount / (currentTermInMonths - 1)
          monthlyTotal = amortisation + fee
          lastAmort = currentAmount - (currentSortTerm - 1) * (currentAmount / (currentTermInMonths - 1)) + currentAmount / (currentTermInMonths - 1)
          lastMonthlyTotal = lastAmort + fee
        }
      }
      monthlyPayments.push({
        amortisation,
        fee,
        months: i,
        lastAmort,
        currentSortTerm,
        monthlyTotal,
        lastMonthlyTotal
      })
    }

    if (firstMonthFree && monthlyPayments.length > 0) {
      monthlyPayments[0].monthlyTotal = 0
    }

    // calculate zero fields
    const zeroMonths = currentTermInMonths - currentSortTerm

    for (let i = 1; i <= zeroMonths; i++) {
      zeroFields.push({
        months: i + currentSortTerm,
        value: 0
      })
    }

    return {
      zeroFields,
      monthlyPayments
    }
  }

  getSelectMonths() {
    const { currentTermInMonths } = this.props
    const input = [];
    let text = `${currentTermInMonths} ${'månad'}`

    for (let i = 1; i <= currentTermInMonths; i++) {
      text = `${i} ${(i === 1) ? 'månad': 'månader'}`

      input.push({
        value: i,
        label: text
      });
    }
    return input
  }

  render() {
    const { selectedSortType } = this.state
    const { showPaymentPlan, handleTogglePaymentPlan, getFormattedAmount, loanCalculator } = this.props
    const paymentsTableData = this.getPaymentsTableData()
    const totalsData = this.getTotalsData()
    const selectMonths = this.getSelectMonths()

    const calculationRowListItems = paymentsTableData.monthlyPayments.map((tableRow, index) => {
      if (index < paymentsTableData.monthlyPayments.length - 1) {
        return (
          <div className="PaymentPlanTableCalculationsRow" key={tableRow.months}>
            <span>{`månad ${tableRow.months}`}</span>
            <span>{ getFormattedAmount(tableRow.monthlyTotal) }</span>
          </div>
        )
      } else {
        return (
          <div className="PaymentPlanTableCalculationsRow" key={tableRow.months}>
            <span>{`månad ${tableRow.months}`}</span>
            <span>{ getFormattedAmount(tableRow.lastMonthlyTotal) }</span>
          </div>
        )
      }
    })

    const zeroRowListItems = paymentsTableData.zeroFields.map((zeroRow) => (
      <div className="PaymentPlanTableZeroRow" key={zeroRow.months}>
        <span>{`månad ${zeroRow.months}`}</span>
        <span>{ getFormattedAmount(zeroRow.value) }</span>
      </div>
    ))

    return (
      <div className="PaymentPlan" data-visible={showPaymentPlan}>
        <OutsideClickHandler
          disabled={!showPaymentPlan}
          onOutsideClick={handleTogglePaymentPlan}
        >
          <div className="PaymentPlanCard">
            <div className="PaymentPlanContent">
              <div className="PaymentPlanClose" onClick={handleTogglePaymentPlan}>
                <i className="icon icon-close" />
              </div>

              <h2>Betalningsplan</h2>

              <h3>Betala snabbare och spara pengar!</h3>

              <div className="PaymentPlanHeaderRow">
                <span>Betala på</span>

                <Select
                  selected={selectedSortType}
                  handleChange={this.changeSelectedSortType}
                  items={selectMonths}
                  theme="secondary"
                />
                { totalsData.saving > 0 && (
                  <div className="PaymentPlanHeaderSaving">
                    <span>och spara</span>

                    <span className="PaymentPlanHeaderSavingAmount">
                      { getFormattedAmount(totalsData.saving) }
                    </span>
                  </div>
                )}

              </div>

              <div className="PaymentPlanTable">
                <div className="PaymentPlanTableCalculations">
                  { calculationRowListItems }
                  { zeroRowListItems }
                </div>
                <div className="PaymentPlanTableTotals">
                  <div className="PaymentPlanTableCalculationsTotal">
                    <span>Totalt</span>
                    <span>{ getFormattedAmount(totalsData.totalTwo) }</span>
                  </div>

                  <div className="PaymentPlanMonthlyFee">
                    <span>Månadsavgift</span>
                    <span>{ getFormattedAmount(loanCalculator.monthlyFee.value) }</span>
                  </div>

                  <div className="PaymentPlanSecondaryTotal">
                    <span>Startavgift</span>
                    <span>{ getFormattedAmount(0) }</span>
                  </div>

                  <div className="PaymentPlanSecondaryTotal">
                    <span>Total avgift</span>
                    <span>{ getFormattedAmount(totalsData.totalFee) }</span>
                  </div>

                  <div className="PaymentPlanSecondaryTotal">
                    <span>Amortering</span>
                    <span>{ getFormattedAmount(totalsData.totalAmortering) }</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  }
}

export default PaymentPlan
