import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Hero.scss'

class Hero extends Component {
  static propTypes = {
    componentData: PropTypes.shape({
      title: PropTypes.string,
      dotpoints: PropTypes.array
    })
  }

  render() {
    const { componentData } = this.props

    if (!componentData || (Object.keys(componentData).length === 0 && componentData.constructor === Object)) {
      return null
    }

    const { title, dotpoints } = componentData
    const dotPointListItems = dotpoints.map((dotpoint, index) => (
      <li key={index} dangerouslySetInnerHTML={{__html: dotpoint.text }} />
    ))

    return (
      <div className="Hero" style={{ backgroundImage: 'url("https://static.qred.com/sv-se/bilder/hero-dsktp.svg")' }}>
        <div className="HeroContent">
          <h2 dangerouslySetInnerHTML={{__html: title}}/>
          <ul>
            { dotPointListItems }
          </ul>
        </div>
      </div>
    )
  }
}

export default Hero
