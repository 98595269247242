import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Contact.scss'

class Contact extends Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    email: PropTypes.string,
    openingHours: PropTypes.string,
    phoneNumber: PropTypes.string
  }

  render() {
    const { title, text, email, openingHours, phoneNumber } = this.props

    return (
      <div className="ContactSection">
        <div className="ContactSectionContent">
          <h2>{ title }</h2>
          <h4>{ text }</h4>
          <div className="ContactInfo">
            <div className="ContactGroup">
              <div className="ContactGroupIcon">
                <i className="icon icon-phone" />
              </div>
              <div className="ContactGroupText">
                <a href={`tel:${phoneNumber}`}>{ phoneNumber }</a>
                <span className="ContactGroupTextSmall">{ openingHours }</span>
              </div>
            </div>
            <div className="ContactGroup">
              <div className="ContactGroupIcon">
                <i className="icon icon-mail" />
              </div>
              <div className="ContactGroupText">
                <a href={`mailto:${email}`}>{ email }</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
