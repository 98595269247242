import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Question from './Question'
import './FAQ.scss'

class FAQ extends Component {
  static propTypes = {
    title: PropTypes.string,
    seeAllLink: PropTypes.shape({
      url: PropTypes.string
    }),
    seeAllText: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string
      })
    )
  }

  constructor(props) {
    super(props)

    this.state = {
      openIndex: null
    }

    this.handleOpen = this.handleOpen.bind(this)
  }

  handleOpen(index) {
    const { openIndex } = this.state

    if (openIndex === index) {
      this.setState({ openIndex: null })
    } else {
      this.setState({ openIndex: index })
    }
  }

  render() {
    const { openIndex } = this.state
    const { title, questions, seeAllLink, seeAllText } = this.props

    return (
      <div className="FAQSection" style={{ backgroundImage: 'url(https://static.qred.com/sv-se/bilder/hero-desktop-secondary.svg)' }}>
        <div className="FAQContentSection">
          <div className="FAQTitle">
            <h2>{title}</h2>
            <a className="FAQLink" href={seeAllLink.url}>{seeAllText}</a>
          </div>
          <div className="FAQQuestions">
            {questions.map((question, index) => (
              <div key={index}>
                <Question
                  answer={question.answer}
                  handleOpen={
                    () => this.handleOpen(index)
                  }
                  isOpen={openIndex === index}
                  question={question.question}
                />
              </div>
            ))}
            <hr />
          </div>
        </div>
      </div>
    )
  }
}

export default FAQ
