import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ApplicationSteps.scss'

class ApplicationSteps extends Component {
  static propTypes = {
    applicationSteps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string
      })
    )
  }

  static defaultProps = {
    applicationSteps: []
  }
  render() {
    const { applicationSteps } = this.props

    return (
      <div className="ApplicationSteps">
        <div className="ApplicationStepsContainer">
          {applicationSteps.map((applicationStep, index) => (
            <div className="ApplicationStep" key={index}>
              <h4 className="ApplicationStepTitle">
                {applicationStep.title}
              </h4>
              <p className="ApplicationStepText">
                {applicationStep.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default ApplicationSteps
