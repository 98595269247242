const parseBlocks = (blocks) => {
  return blocks.map((block) => {
    try {
      return {
        block: block.block,
        rows: block.rows.map((field) => {
          return {
            key:  field.key,
            field: JSON.parse(field.json)
          }
        })
      }
    } catch (err) {
      return false
    }
  }).filter(b => b)
}

export default parseBlocks
